.message {
  display: flex;
  align-items: center;
  padding: 20px;
}

.message-text {
  margin-left: 10px;
  background-color: lightgray;
  padding: 15px;
  border-radius: 20px;
}

.timestamp {
  text-align: center;
  padding: 20px;
  color: gray;
  /* text-transform: uppercase; */
}

.message-text-self {
  margin-left: auto;
  background-color: #29b3cd;
  padding: 15px;
  border-radius: 20px;
  color: white;
}

.message-form {
  display: flex;
  padding: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 600px;
  border-top: 1px solid lightgray;
}

.message-input {
  flex: 1;
  padding: 10px;
  border: none;
}

.message-button {
  border: none;
  padding: 20px;
  background-color: white;
  font-weight: bolder;
  color: #fe3d71;
}
