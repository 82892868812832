.Card {
  position: relative;
  width: 85vw;
  padding: 20px;
  max-width: 600px;
  height: 50vh;
  border-radius: 25px;
  background-size: cover;
  background-position: top;
  /* box-shadow: 0px 18px 53px 0px rgba(0, 0, 0, 0.3); */
  box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2);
}

.Card > h3 {
  position: absolute;
  bottom: 10px;
  color: white;
}

.CardsContainer {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
  width: 100%;
}

.swipe {
  position: absolute;
}
