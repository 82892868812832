.Controls {
  position: fixed;
  bottom: 5vh;
  display: flex;
  justify-content: space-evenly;

  width: 100%;
  max-width: inherit;
  margin: 0;
}

.Controls .MuiIconButton-root {
  background-color: white;
  box-shadow: 0px 10px 53px 0px rgba(0, 0, 0, 0.3) !important;
}

.undo {
  padding: 3% !important;
  color: #f5b748 !important;
}
.reject {
  padding: 3% !important;
  color: #ec5e6f !important;
}
.star {
  padding: 3% !important;
  color: #62b4f9 !important;
}
.match {
  padding: 3% !important;
  color: #76e2b3 !important;
}
.surge {
  padding: 3% !important;
  color: #915dd1 !important;
}
