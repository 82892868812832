.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #f9f9f9; */
}

.header-icon {
  padding: 20px;
}
