.register-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  border-radius: 25px;
  padding: 0 0 25px 0;
  /* width: 500px; */
  max-width: 75vw;
  margin: 75px auto 0px;
  gap: 25px;
  box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
}

.field-set-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 75%;
}

.Register p.auth-message {
  font-weight: 500;
  color: #fff;
  opacity: 1;
  margin-top: 5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
